<template>
  <div class="fr-container pb-10">
    <DsfrBreadcrumb :links="[{ to: '/', text: 'Accueil' }, { text: 'Accessibilité' }]" />
    <h1>Déclaration d'accessibilité</h1>
    <p class="italic">Cette déclaration a été établie le 5 juin 2024</p>
    <p>
      Compl'Alim s’engage à rendre ses services numériques accessibles conformément à l’article 47 de la loi n° 2005-102
      du 11 février 2005.
    </p>
    <p>
      À cette fin, nous mettons en œuvre un schéma pluriannuel présentant la politique en matière d'accessibilité
      numérique (en cours de rédaction) ainsi qu'un plan annuel d'action.
    </p>
    <p>Cette déclaration s'applique au site compl-alim.beta.gouv.fr.</p>
    <h2>État de conformité</h2>
    <p>
      Compl'Alim est non conforme avec le RGAA 4.1.
      <span class="font-bold">Le site n’a pas encore été audité.</span>
    </p>
    <h2>Amélioration et contact</h2>
    <p>
      Si vous n’arrivez pas à accéder à un contenu ou à un service, vous nous contacter par email à l'adresse
      <a href="mailto:contact@compl-alim.beta.gouv.fr">contact@compl-alim.beta.gouv.fr</a>
      pour être orienté vers une alternative accessible ou obtenir le contenu sous une autre forme.
    </p>
    <h2>Voie de recours</h2>
    <p>
      Cette procédure est à utiliser dans le cas suivant : vous avez signalé au responsable du site internet un défaut
      d’accessibilité qui vous empêche d’accéder à un contenu ou à un des services du portail et vous n’avez pas obtenu
      de réponse satisfaisante.
    </p>
    <p>Vous pouvez :</p>
    <ul>
      <li>
        Écrire un message au
        <a href="https://formulaire.defenseurdesdroits.fr/">Défenseur des droits</a>
      </li>
      <li>
        Contacter le délégué d
        <a href="https://www.defenseurdesdroits.fr/saisir/delegues">Défenseur des droits dans votre région</a>
      </li>
      <li>
        Envoyer un courrier par la poste (gratuit, ne pas mettre de timbre) :
        <span class="italic">Défenseur des droits Libre réponse 71120 75342 Paris CEDEX 07.</span>
      </li>
    </ul>
  </div>
</template>

<template>
  <div class="fr-container pb-10">
    <DsfrBreadcrumb :links="[{ to: '/', text: 'Accueil' }, { text: 'Cookies' }]" />
    <h1>Cookies</h1>
    <p>
      Un cookie est un fichier déposé sur votre terminal lors de la visite d’un site. Il a pour but de collecter des
      informations relatives à votre navigation et de vous adresser des services adaptés à votre terminal (ordinateur,
      mobile ou tablette).
    </p>
    <p>
      Le site dépose des cookies de mesure d’audience (nombre de visites, pages consultées), respectant les conditions
      d’exemption du consentement de l’internaute définies par la recommandation « Cookies » de la Commission nationale
      informatique et libertés (CNIL). Cela signifie, notamment, que ces cookies ne servent qu’à la production de
      statistiques anonymes et ne permettent pas de suivre la navigation de l’internaute sur d’autres sites.
    </p>
    <p>
      Nous utilisons pour cela Matomo, un outil de mesure d’audience web libre, paramétré pour être en conformité avec
      la recommandation « Cookies » de la CNIL. Cela signifie que votre adresse IP, par exemple, est anonymisée avant
      d’être enregistrée. Il est donc impossible d’associer vos visites sur ce site à votre personne.
    </p>
    <p>Il convient d’indiquer que :</p>
    <ul>
      <li>Les données collectées ne sont pas recoupées avec d’autres traitements</li>
      <li>Les cookies ne permettent pas de suivre la navigation de l’internaute sur d’autres sites</li>
    </ul>
    <p>
      À tout moment, vous pouvez refuser l’utilisation des cookies et désactiver le dépôt sur votre ordinateur en
      utilisant la fonction dédiée de votre navigateur (fonction disponible notamment sur Microsoft Internet Explorer
      11, Google Chrome, Mozilla Firefox, Apple Safari et Opera).
    </p>
    <p>
      Pour aller plus loin, vous pouvez consulter les fiches proposées par la Commission Nationale de l'Informatique et
      des Libertés (CNIL) :
    </p>
    <ul>
      <li>
        <a href="https://www.cnil.fr/fr/cookies-traceurs-que-dit-la-loi" target="_blank" rel="noopener">
          Cookies & traceurs : que dit la loi ?
        </a>
      </li>
      <li>
        <a href="https://www.cnil.fr/fr/cookies-les-outils-pour-les-maitriser" target="_blank" rel="noopener">
          Cookies : les outils pour les maîtriser
        </a>
      </li>
    </ul>
  </div>
</template>
